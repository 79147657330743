<template>
    <div class="baseMain">
        <div class="baseOperate clearfloat">
            <div class="baseOperateOne tableButton">
                <el-button type="primary" @click="openExport">导出全部</el-button>
            </div>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="当前报名" name="0">
                <div class="baseTable" v-loading="tableLoading">
                    <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData"
                                 :publicTableConfigure="tableConfigure">
                        <el-table-column prop="batch_name" label="批次名称"></el-table-column>
                        <el-table-column sortable prop="number" label="招生人数"></el-table-column>
                        <el-table-column sortable prop="sign_count" label="报名人数"></el-table-column>
                        <el-table-column sortable prop="inscription_count" label="预录取人数"></el-table-column>
                        <el-table-column sortable prop="admission_count" label="录取人数"></el-table-column>
                        <el-table-column label="录取男生/女生">
                            <template #default="scope">
                                <span>{{scope.row.man_count}} / {{scope.row.woman_count}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="drop_count" label="退学人数"></el-table-column>
                    </publicTable>
                </div>
            </el-tab-pane>
            <el-tab-pane label="历史报名" name="1">
                <div class="baseTable" v-loading="tableLoading">
                    <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData"
                                 :publicTableConfigure="tableConfigure">
                        <el-table-column prop="batch_name" label="批次名称"></el-table-column>
                        <el-table-column sortable prop="number" label="招生人数"></el-table-column>
                        <el-table-column sortable prop="sign_count" label="报名人数"></el-table-column>
                        <el-table-column sortable prop="inscription_count" label="预录取人数"></el-table-column>
                        <el-table-column sortable prop="admission_count" label="录取人数"></el-table-column>
                        <el-table-column label="录取男生/女生">
                            <template #default="scope">
                                <span>{{scope.row.man_count}} / {{scope.row.woman_count}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column sortable prop="drop_count" label="退学人数"></el-table-column>
                    </publicTable>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>

    import publicTable from '../../components/publicTable.vue'

    import {businessGet} from '../../api/business.js'

    import {utilsExportExcel} from '../../utils/utils.js'

    export default {
        name: "signupStatistics",
        components: {
            publicTable
        },
        data() {
            return {
                activeName: '0',
                searchHeight: 0,
                tableLoading: false,
                tableData: [],
                tableConfigure: {
                    showSummary: true,
                }
            }
        },
        mounted() {
            this.$nextTick(() => {
                let baseOperateDom = document.querySelector('.baseOperate');
                let searchHeight = baseOperateDom.offsetHeight + 20;
                this.searchHeight = searchHeight;
                this.getTableData()
            })
        },
        methods: {
            openExport() {
                let url = '/admin/v1/sign_up/statistics?is_expire=' + this.activeName + '&is_export=1';
                utilsExportExcel(url)
            },
            handleClick(tab, event) {
                console.log(tab, event);
                this.tableData = [];
                this.getTableData()
            },
            getTableData() {
                let url = '/admin/v1/sign_up/statistics?is_expire=' + this.activeName;
                this.tableLoading = true;
                businessGet(url).then(res => {
                    this.tableLoading = false;
                    if (res.data.status == 200) {
                        let list = res.data.data.list;
                        let tableData = [];
                        list.forEach(item => {
                            if (item.child.length > 0) {
                                item.child.forEach(son => {
                                    tableData.push(son)
                                })
                            }
                        })
                        this.tableData = tableData;
                    }
                    else {
                        this.$message({
                            showClose: true,
                            message: res.data.message,
                            type: 'warning'
                        });
                    }
                })
            },
        }
    }
</script>

<style scoped>

    @import "../../assets/css/base.css";

</style>
